<template>
  <div class="position-relative d-flex clickable-item-hov" @click="addProfileToSection">
    <div class="d-flex flex-column flex-grow-1 w-100 position-relative">
      <div>
        <PlusTag
          v-if="featureSectioMap.includes(profile) && subscriptionLevel === 0"
          size="sm"
          style="position: absolute; top: -5px; left: 12px"
        />
        <div class="profile-content">
          <i class="ti-plus icon mr-2" />
          {{ possibleSectionsLabelMap[profile] === 'About' ? 'Traits' : possibleSectionsLabelMap[profile] }}
        </div>
      </div>
    </div>
    <FeaturePreviewModal
      :isOpen="!!featurePreviewModalIsOpen"
      :previewKey="featurePreviewModalKey"
      :previewName="featurePreviewModalFeatureName"
      @close="closeFeaturePreviewModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { possibleSectionsLabelMap, unlockableSectionsLabelMap } from '@/shared/statics/new-character-profile';
import { characterEditorStore } from '../pinia-store/character-editor';
import { authStore } from '../pinia-store/auth';
import { featureFlags } from '@/shared/config/feature-flags';
import { openSubscribeModal } from '@/shared/utils/modals';
import FeaturePreviewModal from '@/shared/modals/FeaturePreviewModal.vue';
const { addSection } = characterEditorStore();
const props = defineProps({
  profile: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['added']);
const profile = toRef(props, 'profile');
const featureSectioMap = ['alignment_charts', 'timeline', 'qualities', 'trivia'];
const featurePreviewModalKey = ref('');
const featurePreviewModalFeatureName = ref('');
const featurePreviewModalIsOpen = ref(false);
const { subscriptionLevel } = authStore();
const closeFeaturePreviewModal = (actionKey?: string) => {
  featurePreviewModalIsOpen.value = false;
  setTimeout(() => {
    featurePreviewModalKey.value = '';
    featurePreviewModalFeatureName.value = '';
  }, 100);
  if (actionKey === 'openSubscribeModal') {
    openSubscribeModal(null, 'characterAddSection');
  }
};
const openFeaturePreviewModal = (key: any, value: any) => {
  featurePreviewModalIsOpen.value = true;
  featurePreviewModalKey.value = key;
  featurePreviewModalFeatureName.value = value;
};

const unlockableSections = computed(() => {
  const { subscriptionLevel } = authStore();
  if (!featureFlags.pro) return {};
  if (subscriptionLevel.value) {
    return {};
  }
  return unlockableSectionsLabelMap;
}) as any;

const addProfileToSection = () => {
  const { userPro } = authStore();
  if (
    (!get(userPro.value, 'feature_alignmentcharts') && profile.value === 'alignment_charts') ||
    (!get(userPro.value, 'attributesliders') && profile.value === 'qualities') ||
    (!get(userPro.value, 'feature_timeline') && profile.value === 'timeline') ||
    (!get(userPro.value, 'feature_trivia') && profile.value === 'trivia')
  ) {
    openFeaturePreviewModal(profile.value, unlockableSections.value[profile.value as string]);
    return;
  }
  addSection(profile.value);
  emits('added', profile.value);
};
</script>

<style lang="sass" scoped>
.icon
  font-size: 11px
.profile-content
  padding: 3px
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  --width: 100%
  --height: 28px
  border-radius: 20px
  border: 2px solid #ae38e5
  font-size: 14px
  color: #ae38e5
</style>
