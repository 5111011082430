<template>
  <div v-if="!isEmpty(currentCharacterProfileSections)">
    <div class="add-sec-title mb-2">Add Sections:</div>
    <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
      <AddToProfileSmallCard
        class="grid-item"
        v-for="(profile, index) of currentCharacterProfileSections"
        :profile="profile"
        :key="index"
        @added="removeProfileSections"
      />
    </Grid>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import AddToProfileSmallCard from '~/shared/components/AddToProfileSmallCard.vue';
import { characterEditorStore } from '~/shared/pinia-store/character-editor';
const { currentCharacterProfileSections, changeProfileSections } = characterEditorStore();

const removeProfileSections = (value: string) => {
  changeProfileSections(value);
};
</script>
<style lang="sass" scoped>
.add-sec-title
  font-size: 16px
  font-weight: bold
.prof-content
   border: 2px solid #ae38e5
   border-radius: 20px
   width: 13vw

   display: flex
   align-items: center
   justify-content: center
   padding: 3px
</style>
