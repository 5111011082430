<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    style="background: #3b3a53ad"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content p-3">
      <div class="title flex-column">
        <div><span class="text-primary">BOOYAH</span>!</div>
        <div>YOU'VE <span class="text-primary">CREATED A CHARACTER</span>!</div>
      </div>

      <div class="d-flex justify-content-center flex-column align-items-center">
        <inline-svg class="my-2" src="/ch-creation.svg" />
        <ion-button class="sure-btn" @click="close">I sure did! :D</ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(['close']);

const close = (val: any) => {
  emits('close', val);
};
</script>

<style lang="sass" scoped>
.sure-btn
  --background: #8928C4
  width:40%

.dark .toolbar
  --background: #010123 !important

.modal-big
  --width: 400px
  --height: auto
  --border-radius: 9px

.title
  font-weight: bold
  font-size: 22px
  color: #431c89
.dark .title
  color: white !important
.sub-title
  color: #431c89
  font-weight: 700
.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 88dvh
  overflow: auto
</style>
