<template>
  <client-only>
    <ion-content class="modal-content-self h-100" ref="modal" scroll-y="false">
      <div class="content d-flex flex-column h-100">
        <ion-header>
          <ion-toolbar>
            <div class="w-100 px-2 text-center title ion-text-center" mode="ios">Add section</div>
            <ion-buttons slot="end">
              <ion-button class="close-button" @click="dismissModal()">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div v-auto-animate class="inner-content p-3 flex-grow-1">
          <div class="options editor-section-options h-100">
            <div v-for="(section, index) of allSections" :key="index">
              <div
                class="one-option d-flex justify-content-between align-items-center"
                @click="addSectionAction(section.value)"
              >
                <div class="d-flex align-items-center no-select">
                  {{ section.label }}
                </div>
                <div v-if="section.isUnlockable" class="d-flex justify-content-between">
                  <PlusTag size="lg" />
                </div>
                <div
                  v-if="hotCharacterEditorSections.includes(section.value)"
                >
                  🔥
                </div>
              </div>

              <hr v-if="isNotLast(index, possibleSectionsLabelMap)" class="m-0" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </client-only>
</template>
<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import { possibleSectionsLabelMap, unlockableSectionsLabelMap } from '@/shared/statics/new-character-profile';
import { hotCharacterEditorSections } from '@/shared/statics/constants';

const props = defineProps({
  instance: {
    type: Object,
  },
  sections: {
    type: Object,
  },
  unlockableSections: {
    type: Object,
  },
});

const unlockableSections = toRef(props, 'unlockableSections');
const sections = toRef(props, 'sections');

const instance = toRef(props, 'instance');

const { trackEvent } = useAnalytics();

const addSectionAction = (key: string) => {
  const { subscriptionLevel } = authStore();
  if (subscriptionLevel.value && Object.keys(unlockableSectionsLabelMap).includes(key)) {
    trackEvent(`Premium feature used: Character Editor - ${key}`, null, true);
  }

  if (!instance.value) return;

  instance.value.dismiss(key);
};

const allSections = computed(() => {
  const all: any[] = [];
  const sectionsEntries = Object.entries(sections.value || {});
  sectionsEntries.forEach(([key, value], index) => {
    all.push({
      value: key,
      label: value,
      priority: index,
      isUnlockable: false,
    });
  });

  Object.entries(unlockableSections.value || {}).forEach(([key, value], index) => {
    const priority =
      key === 'timeline'
        ? 6.5
        : key === 'alignment_charts'
        ? 8.5
        : key === 'qualities'
        ? 10.5
        : sectionsEntries.length + index;
    all.push({
      value: key,
      label: value,
      priority,
      isUnlockable: true,
    });
  });

  return all.sort((a, b) => a.priority - b.priority);
});

const dismissModal = () => {
  if (!instance.value) return;

  instance.value.dismiss();
};

const isNotLast = (index: number, object: any) => {
  return index < Object.keys(object).length;
};
</script>

<style lang="sass" scoped>
.inner-content
  height: calc(100% - 60px)
.editor-section-options
  overflow-y: auto
.options
  padding: 10px
  background: #fff
  border-radius: 20px
  .one-option
    height: 40px
    padding: 0 15px
    cursor: pointer
    &:hover
      background: #EFE2FF
      font-weight: bold
</style>
