<template>
  <div v-if="ready" class="text">
    <div class="title mb-3">Add to folder</div>
    <ion-select
      v-if="allCollections && allCollections.length"
      v-model="mutatedCharacterCollections"
      mode="md"
      placeholder="Select folders you want your character to be a part of"
      :multiple="true"
      class="bg-white select pr-2 text"
    >
      <ion-select-option v-for="collection in allCollections" :key="collection.id" :value="collection.id">{{
        collection.name
      }}</ion-select-option>
    </ion-select>
    <span v-else> Folders let you organize your characters and worlds! </span>
  </div>
</template>

<script lang="ts" setup>
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { getCharacterCollections } from '@/shared/actions/collections';
import { authStore } from '@/shared/pinia-store/auth';

const { user } = authStore();

const { changeKey } = characterEditorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: [],
  },
});

const ready = ref(true);
const characterCollections = toRef(props, 'value');
const loggedInUser = computed(() => user.value);
const allCollections = ref([] as any[]);

const fetchUserCollections = async () => {
  try {
    const response = await getCharacterCollections(loggedInUser.value.id);
    allCollections.value = response;
  } catch (_e) {
    // await toast.show('Could not load collections. Please try again.', 'nonative', 'danger');
  }
};

onMounted(async () => {
  await fetchUserCollections();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const mutatedCharacterCollections = computed({
  get() {
    return characterCollections.value;
  },
  set(val) {
    changeKey('character_collections', val);
  },
});
</script>

<style lang="sass" scoped>
.title
  font-size: 16px
  font-weight: bold
.select
  border-radius: 10px
  padding: 0 10px
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
</style>
